import FormControl from '@material-ui/core/FormControl';
// material import
import { 
    Grid, 
    InputLabel,
    FormControlLabel,
    Button,
    TextField,
    Select,
    Switch,
    Backdrop,
    Modal,
    Fade,
    Typography,
    makeStyles,
} from '@material-ui/core';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import * as _ from 'lodash';
import React, { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import html2canvas from "html2canvas";
import { saveAs } from 'file-saver';
import PptxGenJS from 'pptxgenjs';
import moment from "moment-timezone";
import { graphidnamemap } from '../../../../../common/constants/constants';
// USerdefined component import
import Loader from '../../../../../common/dialogs/loader/loader';
import Dropdown from '../../../../ui_utilities/Dropdown';
import ModalFlyout from '../../../../ui_utilities/modalFlyout';
import purina_logo from '../../../../../assets/images/logo-addie-teal.png';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        width: '600px',
        height: '770px',
        border: '2px solid transparent',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        overflow: 'auto',
    }
}));

const ChartAndGraphs = (props) => {
    const DEFAULT_CHART_WIDTH = "half_page";
    const classes = useStyles();

    // charts useRef
    const ecmChartRef = useRef(null);
    const milkprodChartRef = useRef(null);
    const lact1ChartRef = useRef(null);
    const lact2ChartRef = useRef(null);
    const lact3ChartRef = useRef(null);
    const herddataChartRef = useRef(null);
    const firstcalfheiferChartRef = useRef(null);
    const daysOpenChartRef = useRef(null);
    const percentFatChartRef = useRef(null);
    const somaticCellChartRef = useRef(null);
    const percentProteinChartRef = useRef(null);
    const totalLbsFatproteinChartRef = useRef(null);
    const averageDimChartRef = useRef(null);
    const calvingIntervalsChartRef = useRef(null);
    const annualCullChartRef = useRef(null);
    const lactCurveChartRef = useRef(null);

    const [loaderstatus, setloaderstatus] = useState(false);

    const [graphList, setGraphlist] = useState([]);
    const [selectedGraph, setSelectedGraph] = useState([]);
    const [addchartflag, setaddchartflag] = useState(false);

    const [showpreview, setshowpreview] = useState(false);
    const [previewchartid, setpreviewchartid] = useState(0);
    const [removecounter, setremovecounter] = useState(0)
    const [openModal, setOpenModal] = useState(false);

    const [graphbloburl, setgraphbloburl] = useState([]);
    const useavgdailymilkflag = props.subherddata.use_avg_daily_milk ? props.subherddata.use_avg_daily_milk : false;
    
    const [graphTemplate, setGraphTemplate] = useState(props.graphTemplate);
    const [updatedChartWidth, setUpdatedCartWidth] = useState(false);

    useEffect(() => {
        setGraphTemplate(props.graphTemplate);
    }, [props.graphTemplate]);

    useEffect(() => {
        if (removecounter === 0) {
            if (useavgdailymilkflag) {

                props.selectedGraph[1] = {
                    key: 2, "id": "weeklymilkchart", "name": "Weekly Milk", "value": true, "width": "half_page"
                }
            } else {
                props.selectedGraph[1] = {
                    key: 2, "id": "testdaymilkchart", "name": "Test Day Milk", "value": true, "width": "half_page"
                }
            }
        }
        let lactationcurvemaxy = props.lactationcurvemaxy;
        let lactationcurveminy = props.lactationcurveminy;
        let lactationgraphdontshowgoal = ""

        const getoptions = (chartType, chartLabelColor, category, id, name, xaxistext, yaxistext) => {
            let colors = ['#e87722', '#204F56', '#E025A5', '#530FDD', '#00B1AD', '#47292B', '#B00020'], stroke = {
                curve: "straight",
            };

            let legend = {
                horizontalAlign: 'right',
                position: "top",
                showForSingleSeries: true,
                formatter: function (seriesName, opts) {
                    let header = opts.w.config.series[opts.seriesIndex].header
                    if (props.reporttab === "periodcomparison" || props.reporttab === "subherdcomparison") {
                        let pattern = /\d{4}-\d{2}-\d{2}/g
                        if (pattern.test(header)) {
                            let match = header.match(pattern);
                            if (match.length === 2) {
                                return match[0] === match[1] ? match[0] : header
                            }
                        }
                    }
                    return header
                }
            }

            let tooltip = {
                x: {
                    format: "dd/MM/yy",
                },
            }
            let yaxis = {
                min: 0,
                labels: {
                    style: {
                        fontSize: '15px',
                    }
                },
                title: {
                    text: yaxistext,
                    style: {
                        fontSize: '15px',
                        fontWeight: '700',
                    },
                },
            }

            let xaxis = {
                type: "string",
                labels: {
                    style: {
                        fontSize: '15px',
                    }
                },
                title: {
                    text: xaxistext,
                    style: {
                        fontSize: '15px',
                        fontWeight: '700',
                    },
                },
                "categories": category,
                tickPlacement: 'between',
            }

            if (props.reporttab === "periodcomparison" || props.reporttab === "subherdcomparison") {
                tooltip.y = { title: { 
                    formatter: function (seriesName, opts) {
                        let header = opts.w.config.series.filter((d)=> { return d.name === seriesName})[0].header
                        if (props.reporttab === "periodcomparison" || props.reporttab === "subherdcomparison") {
                            let pattern = /\d{4}-\d{2}-\d{2}/g
                            if (pattern.test(header)) {
                                let match = header.match(pattern);
                                if (match.length === 2) {
                                    return match[0] === match[1] ? match[0] : header
                                }
                            }
                        } 
                        return header
                    }
                }, formatter: function(val) { 
                    if (val === null) {
                        return 0;
                    }
                    return !isNaN(val) ? val.toFixed(2) : 0;
                }}
            }

            if (!["percentfatchart", "percentproteinchart", "totallbschart"].includes(id)) {
                yaxis.decimalsInFloat = 0;
            } else {
                switch (id) {
                    case "percentfatchart": //6, 4 ticks
                        yaxis.tickAmount = 6
                        break;
                    case "percentproteinchart": //5, 4 ticks
                        yaxis.tickAmount = 5
                        break;
                    case "totallbschart": //no limit, 4 ticks
                    default:
                        let data = [];
                        props.graphdataresponse.lbs_fat_protein_data[0].ComponentAnalysis_data.forEach((ele) => {
                            data.push(...ele.data)
                        })
                        let num = parseInt(data.reduce((a, b) => { return Math.max(a, b) }))
                        if (num <= 4) {
                            yaxis.tickAmount = 4
                        } else {

                            if (num > 20)
                                num = 15
                            yaxis.tickAmount = num
                        }
                }
            }

            let convert_peer_to_national_avg_header = props.reporttab === "benchmark" && typeof(props.reportResponse.Peer_Footer_Definition?.Peer_Average_Availability) !== 'undefined';
            if (["snapsix", "snapshot", "benchmark", "periodcomparison", "subherdcomparison"].includes(props.reporttab)) {
                if (!["percentfatchart", "percentproteinchart", "totallbschart"].includes(id)) {
                    yaxis.decimalsInFloat = 0;
                    if (["ecmchart", "weeklymilkchart", "testdaymilkchart", "herddatachart", "calfheiferchart", "daysopenchart", "calvingintervalchart", "somaticcellchart", "avgdimchart", "annualcullchart"].includes(id)) {
                        // yaxis.labels.formatter = function(val, index) {
                        //     return val.toFixed(0);
                        // }
                        yaxis.decimalsInFloat = 0;
                        let data = []
                        let min = Math.round(lactationcurveminy / 10) * 10;
                        let max = Math.round(lactationcurvemaxy / 10) * 10;
                        if(id === "ecmchart" && props.graphdataresponse.ecm_data[0].ECM_data.length > 0) {
                            props.graphdataresponse.ecm_data[0].ECM_data.forEach((ele, idx, arr) => {
                                arr[idx].header = convert_peer_to_national_avg_header ? arr[idx].header.replace('Peer Average', 'National Average') : arr[idx].header;
                                arr[idx].name = convert_peer_to_national_avg_header ? arr[idx].name.replace('Peer Average', 'National Average') : arr[idx].name;
                                data.push(...ele.data)
                            })
                        } 
                        if(id === "weeklymilkchart" && props.graphdataresponse.weekly_milk_data[0].Latest_Weekly_Milk_data.length > 0) {
                            props.graphdataresponse.weekly_milk_data[0].Latest_Weekly_Milk_data.forEach((ele, idx, arr) => {
                                arr[idx].header = convert_peer_to_national_avg_header ? arr[idx].header.replace('Peer Average', 'National Average') : arr[idx].header;
                                arr[idx].name = convert_peer_to_national_avg_header ? arr[idx].name.replace('Peer Average', 'National Average') : arr[idx].name;
                                data.push(...ele.data)
                            })
                        } 
                        else if(id === "testdaymilkchart" && props.graphdataresponse.test_day_milk_data[0].Test_Day_Milk_data.length > 0) {
                            props.graphdataresponse.test_day_milk_data[0].Test_Day_Milk_data.forEach((ele, idx, arr) => {
                                arr[idx].header = convert_peer_to_national_avg_header ? arr[idx].header.replace('Peer Average', 'National Average') : arr[idx].header;
                                arr[idx].name = convert_peer_to_national_avg_header ? arr[idx].name.replace('Peer Average', 'National Average') : arr[idx].name;
                                data.push(...ele.data)
                            })
                        }
                        else if (id === "herddatachart" && props.graphdataresponse.percent_pregnant_in_herd_data[0].Reproduction_data.length > 0) {
                            props.graphdataresponse.percent_pregnant_in_herd_data[0].Reproduction_data.forEach((ele, idx, arr) => {
                                arr[idx].header = convert_peer_to_national_avg_header ? arr[idx].header.replace('Peer Average', 'National Average') : arr[idx].header;
                                arr[idx].name = convert_peer_to_national_avg_header ? arr[idx].name.replace('Peer Average', 'National Average') : arr[idx].name;
                                data.push(...ele.data)
                            })
                        }
                        else if (id === "calfheiferchart" && props.graphdataresponse.first_calf_heifer_profiles_data[0].HerdInventory_data.length > 0) {
                            props.graphdataresponse.first_calf_heifer_profiles_data[0].HerdInventory_data.forEach((ele, idx, arr) => {
                                arr[idx].header = convert_peer_to_national_avg_header ? arr[idx].header.replace('Peer Average', 'National Average') : arr[idx].header;
                                arr[idx].name = convert_peer_to_national_avg_header ? arr[idx].name.replace('Peer Average', 'National Average') : arr[idx].name;
                                data.push(...ele.data)
                            })
                        }
                        else if (id === "daysopenchart" && props.graphdataresponse.days_open_data[0].Reproduction_data.length > 0) {
                            if (props.reporttab === "snapsix") {
                                props.graphdataresponse.days_open_data[0].Reproduction_data.forEach((ele) => {
                                    if (ele.name === "Days_Open_Pregnant_Cows") {
                                        data.push(...ele.data);
                                    }
                                })
                            }
                            else {
                                props.graphdataresponse.days_open_data[0].Reproduction_data.forEach((ele, idx, arr) => {
                                    arr[idx].header = convert_peer_to_national_avg_header ? arr[idx].header.replace('Peer Average', 'National Average') : arr[idx].header;
                                    arr[idx].name = convert_peer_to_national_avg_header ? arr[idx].name.replace('Peer Average', 'National Average') : arr[idx].name;
                                        data.push(...ele.data);
                                })
                            }
                        }
                        else if (id === "calvingintervalchart" && props.graphdataresponse.calving_interval[0].Reproduction_data.length > 0) {
                            props.graphdataresponse.calving_interval[0].Reproduction_data.forEach((ele, idx, arr) => {
                                arr[idx].header = convert_peer_to_national_avg_header ? arr[idx].header.replace('Peer Average', 'National Average') : arr[idx].header;
                                arr[idx].name = convert_peer_to_national_avg_header ? arr[idx].name.replace('Peer Average', 'National Average') : arr[idx].name;
                                data.push(...ele.data);
                            })
                        }
                        else if (id === "somaticcellchart" && props.graphdataresponse.somatic_cell_count[0].Milk_Quality_data.length > 0) {
                            props.graphdataresponse.somatic_cell_count[0].Milk_Quality_data.forEach((ele, idx, arr) => {
                                arr[idx].header = convert_peer_to_national_avg_header ? arr[idx].header.replace('Peer Average', 'National Average') : arr[idx].header;
                                arr[idx].name = convert_peer_to_national_avg_header ? arr[idx].name.replace('Peer Average', 'National Average') : arr[idx].name;
                                data.push(...ele.data);
                            })
                        }
                        else if (id === "avgdimchart" && props.graphdataresponse.average_dim[0].Average_DIM_data.length > 0) {
                            props.graphdataresponse.average_dim[0].Average_DIM_data.forEach((ele, idx, arr) => {
                                arr[idx].header = convert_peer_to_national_avg_header ? arr[idx].header.replace('Peer Average', 'National Average') : arr[idx].header;
                                arr[idx].name = convert_peer_to_national_avg_header ? arr[idx].name.replace('Peer Average', 'National Average') : arr[idx].name;
                                data.push(...ele.data);
                            })
                        }
                        else if (id === "annualcullchart" && props.graphdataresponse.annual_cull_and_death_rates[0].Culling_data.length > 0) {
                            props.graphdataresponse.annual_cull_and_death_rates[0].Culling_data.forEach((ele, idx, arr) => {
                                arr[idx].header = convert_peer_to_national_avg_header ? arr[idx].header.replace('Peer Average', 'National Average') : arr[idx].header;
                                arr[idx].name = convert_peer_to_national_avg_header ? arr[idx].name.replace('Peer Average', 'National Average') : arr[idx].name;
                                data.push(...ele.data);
                            })
                        }
                        else if (id === "percentfatchart" && props.graphdataresponse.percent_fat_data[0].Percent_Fat_data.length > 0) {
                            props.graphdataresponse.percent_fat_data[0].Percent_Fat_data.forEach((ele, idx, arr) => {
                                arr[idx].header = convert_peer_to_national_avg_header ? arr[idx].header.replace('Peer Average', 'National Average') : arr[idx].header;
                                arr[idx].name = convert_peer_to_national_avg_header ? arr[idx].name.replace('Peer Average', 'National Average') : arr[idx].name;
                                data.push(...ele.data);
                            })
                        }
                        if (data.length > 0) {
                            data = data.filter(n => n);
                            if (data.length > 0) {
                                min = parseInt(data.reduce((a, b) => { return Math.min(a, b) }));
                                max = parseInt(data.reduce((a, b) => { return Math.max(a, b) }));
                            }
                        }

                        if (["snapsix"].includes(props.reporttab)) {
                            yaxis.min = (Math.round(min / 10) * 10) - 10;
                            yaxis.max = (Math.round(max / 10) * 10) + 10;
                            yaxis.min = yaxis.min <= 0 ? 0 : yaxis.min; 
                            yaxis.tickAmount = parseInt(((yaxis.max - yaxis.min) / 10));
                            tooltip.y = { formatter: function(val) {
                                if (val === null) {
                                    return 0;
                                }
                                return !isNaN(val) ? val.toFixed(2) : 0;
                            }}
                        }
                        else if (["snapshot", "benchmark", "periodcomparison", "subherdcomparison"].includes(props.reporttab)) {
                            yaxis.min = 0;
                            yaxis.max = (Math.round(max / 10) * 10) + 10;
                            yaxis.tickAmount = parseInt(((yaxis.max - yaxis.min) / 10));
                            if (["avgdimchart", "daysopenchart"].includes(id)) {
                                yaxis.max = (Math.round(max / 20) * 20) + 20;
                                yaxis.tickAmount = parseInt(((yaxis.max - yaxis.min) / 20));
                            }
                            else if (["calvingintervalchart"].includes(id)) {
                                yaxis.max = (Math.round(max / 50) * 50) + 50;
                                yaxis.tickAmount = parseInt(((yaxis.max - yaxis.min) / 50));
                            }
                        }
                    }
                } else {
                    yaxis.labels.formatter = function(val, index) {
                        if (val === null) {
                            return 0;
                        }
                        return val.toFixed(0);
                    }
                    tooltip.y = { formatter: function(val) {
                        if (val === null) {
                            return 0;
                        }
                        return !isNaN(val) ? val.toFixed(2) : 0;
                    }}
                    let data = [];
                    if (id === "percentfatchart" && props.graphdataresponse.percent_fat_data[0].Percent_Fat_data.length > 0) {
                        props.graphdataresponse.percent_fat_data[0].Percent_Fat_data.forEach((ele, idx, arr) => {
                            arr[idx].header = convert_peer_to_national_avg_header ? arr[idx].header.replace('Peer Average', 'National Average') : arr[idx].header;
                            arr[idx].name = convert_peer_to_national_avg_header ? arr[idx].name.replace('Peer Average', 'National Average') : arr[idx].name;
                            data.push(...ele.data)
                        })
                    }
                    else if (id === "percentproteinchart" && props.graphdataresponse.percent_protein[0].Percent_Protein_data.length > 0) {
                        props.graphdataresponse.percent_protein[0].Percent_Protein_data.forEach((ele, idx, arr) => {
                            arr[idx].header = convert_peer_to_national_avg_header ? arr[idx].header.replace('Peer Average', 'National Average') : arr[idx].header;
                            arr[idx].name = convert_peer_to_national_avg_header ? arr[idx].name.replace('Peer Average', 'National Average') : arr[idx].name;
                            data.push(...ele.data)
                        })
                    }
                    else {
                        props.graphdataresponse.lbs_fat_protein_data[0].ComponentAnalysis_data.forEach((ele, idx, arr) => {
                            arr[idx].header = convert_peer_to_national_avg_header ? arr[idx].header.replace('Peer Average', 'National Average') : arr[idx].header;
                            arr[idx].name = convert_peer_to_national_avg_header ? arr[idx].name.replace('Peer Average', 'National Average') : arr[idx].name;
                            data.push(...ele.data)
                        })
                    }
                    let num = parseInt(data.reduce((a, b) => { return Math.max(a, b) }))
                    yaxis.max = num + 1;
                    yaxis.tickAmount = num;
                    if (yaxis.tickAmount < yaxis.max) {
                        yaxis.tickAmount = yaxis.max;
                    }
                }
            }

            if (["lactation1chart", "lactation2chart", "lactation3chart"].includes(id)) {
                yaxis.labels.formatter = function(val, index) {
                    return val.toFixed(0);
                }
                tooltip.y = { title: { 
                    formatter: function (seriesName, opts) {
                        let header = opts.w.config.series.filter((d)=> { return d.name === seriesName})[0].header
                        if (props.reporttab === "periodcomparison" || props.reporttab === "subherdcomparison") {
                            let pattern = /\d{4}-\d{2}-\d{2}/g
                            if (pattern.test(header)) {
                                let match = header.match(pattern);
                                if (match.length === 2) {
                                    return match[0] === match[1] ? match[0] : header
                                }
                            }
                        }
                        else if (convert_peer_to_national_avg_header) {
                            return header.replace("Peer Average", "National Average");
                        }
                        return header
                    }
                }, formatter: function(val) {
                    if (val === null) {
                        return 0;
                    }
                    return !isNaN(val) ? val.toFixed(2) : 0;
                }}
                yaxis.decimalsInFloat = 2;
                yaxis.min = Math.round(lactationcurveminy / 10) * 10;
                yaxis.max = Math.round(lactationcurvemaxy / 10) * 10;
                let tickNumber = yaxis.max - yaxis.min >= 200 && ["snapsix", "periodcomparison", "snapshot", "benchmark", "subherdcomparison"].includes(props.reporttab) ? 20 : 10;
                yaxis.tickAmount = parseInt(((yaxis.max - yaxis.min) / tickNumber));
                legend = {
                    position: "top",
                    showForSingleSeries: true,
                    markers: {
                        width: 20,
                        height: 4,
                        strokeWidth: 0,
                    },
                    formatter: function (seriesName, opts) {
                        let header = opts.w.config.series[opts.seriesIndex].header
                        if (props.reporttab === "periodcomparison" || props.reporttab === "subherdcomparison") {
                            let pattern = /\d{4}-\d{2}-\d{2}/g
                            if (pattern.test(header)) {
                                let match = header.match(pattern);
                                if (match.length === 2) {
                                    return match[0] === match[1] ? match[0] : header
                                }
                            }
                        }
                        else if (convert_peer_to_national_avg_header) {
                            return header.replace("Peer Average", "National Average")
                        }
                        return header
                    }
                }
                // where you define the colors, with and lines for the chart
                if (props.reporttab === "snapshot") {
                    if (lactationgraphdontshowgoal === "Herd_Goal_(60)") {
                        colors = ['rgb(232, 119, 34)', 'rgb(32, 79, 86)', 'rgba(32, 79, 86, .5)', 'rgb(224, 37, 165)'];
                        stroke.dashArray = [null, null, 10, null];
                        legend.markers.width = [20, 20, 10, 20]
                    } else if (lactationgraphdontshowgoal === "Herd_Goal_(115)") {
                        colors = ['rgb(232, 119, 34)', 'rgba(32, 79, 86, .5)', 'rgb(32, 79, 86)', 'rgb(224, 37, 165)'];
                        stroke.dashArray = [null, 10, null, null];
                        legend.markers.width = [20, 10, 20, 20]
                    } else {
                        colors = ['rgb(232, 119, 34)', 'rgba(32, 79, 86, .5)', 'rgb(32, 79, 86)', 'rgba(32, 79, 86, .5)', 'rgba(224, 37, 165, 1)'];
                        stroke.dashArray = [null, 10, null, 10, null];
                        legend.markers.width = [20, 10, 20, 10, 20]
                    }
                }
                else if (props.reporttab === "periodcomparison") {
                    if (lactationgraphdontshowgoal === "Herd_Goal_(60)") {
                        colors = ['rgb(232, 119, 34)', 'rgb(37, 150, 190)', 'rgb(32, 79, 86)', 'rgba(32, 79, 86, .5)'];
                        stroke.dashArray = [null, null, null, 10];
                        legend.markers.width = [20, 20, 20, 8];
                    } else if (lactationgraphdontshowgoal === "Herd_Goal_(115)") {
                        colors = ['rgb(232, 119, 34)', 'rgb(37, 150, 190)', 'rgba(32, 79, 86, .5)', 'rgb(32, 79, 86)'];
                        stroke.dashArray = [null, null, 10, null];
                        legend.markers.width = [20, 20, 8, 20];
                    } else {
                        // colors = ['rgb(232, 119, 34)', 'rgb(32, 79, 86)', 'rgba(32, 79, 86, .5)', 'rgba(32, 79, 86, .5)', 'rgba(224, 37, 165, 1)'];
                        // stroke.dashArray = [null, null, 10, 5, null];
                        // legend.markers.width = [20, 20, 10, 10, 20]
                        colors = ['rgb(232, 119, 34)', 'rgb(37, 150, 190)', 'rgba(32, 79, 86, .5)', 'rgb(32, 79, 86)', 'rgba(32, 79, 86, .5)'];
                        stroke.dashArray = [null, null, 10, null, 10];
                        legend.markers.width = [20, 20, 8, 20, 8];
                    }
                } else if (props.reporttab === "subherdcomparison") {
                    if (lactationgraphdontshowgoal === "Herd_Goal_(60)") {
                        colors = ['rgb(232, 119, 34)', 'rgb(37, 150, 190)', 'rgb(32, 79, 86)', 'rgba(32, 79, 86, .5)'];
                        stroke.dashArray = [null, null, null, 10];
                        legend.markers.width = [20, 20, 20, 8];
                    } else if (lactationgraphdontshowgoal === "Herd_Goal_(115)") {
                        colors = ['rgb(232, 119, 34)', 'rgb(37, 150, 190)', 'rgba(32, 79, 86, .5)', 'rgb(32, 79, 86)'];
                        stroke.dashArray = [null, null, 10, null];
                        legend.markers.width = [20, 20, 8, 20];
                    } else {
                        colors = ['rgb(232, 119, 34)', 'rgb(32, 79, 86)', 'rgba(224, 37, 165, 1)', 'rgba(83, 15, 221, 1)', 'rgba(0, 177, 173, 1)', 'rgba(71, 41, 43, 1)', 'rgba(176, 0, 32, 1)'];
                        stroke.dashArray = "";
                        legend.markers.width = [20, 20, 20, 20, 20, 20]
                    }
                } else {
                    legend.markers.width = 20;
                }

                if (props.reporttab !== "benchmark" && (useavgdailymilkflag || props.reportResponse?.Use_Avg_Daily_Milk)) {
                    xaxis = {
                        type: "numeric",
                        tickAmount: 37,
                        min: 0,
                        labels: {
                            style: {
                                fontSize: '15px',
                            },
                            formatter: function (value) {
                                return [2, 4, 8, 15, 21, 25, 32, 37].includes(value) ? value : "";
                            }
                        },
                        title: {
                            text: xaxistext,
                            style: {
                                fontSize: '15px',
                                fontWeight: '700',
                            },
                        },
                        "categories": category
                    }
                }
            }

            if (id === "lactationcurvechart") {
                yaxis.labels.formatter = function(val, index) {
                    return val.toFixed(0);
                }
                yaxis.decimalsInFloat = 2
                yaxis.min = Math.round(lactationcurveminy / 10) * 10;
                yaxis.max = Math.round(lactationcurvemaxy / 10) * 10;
                let tickNumber = yaxis.max - yaxis.min >= 200 && ["snapsix", "periodcomparison", "snapshot", "benchmark", "subherdcomparison"].includes(props.reporttab) ? 20 : 10;
                yaxis.tickAmount = parseInt(((yaxis.max - yaxis.min) / tickNumber));
                tooltip.marker = {
                    show: true
                }
                tooltip.y = { title: { 
                    formatter: function (seriesName, opts) {
                        let header = opts.w.config.series.filter((d)=> { return d.name === seriesName })[0].header;
                        if (convert_peer_to_national_avg_header) {
                            return header.replace("Peer Average", "National Average")
                        }
                        return header
                    }
                }, formatter: function(val) { 
                    if (val === null) {
                        return 0;
                    }
                    return !isNaN(val) ? val.toFixed(2) : 0;
                }}

                colors = ['#e87722', '#204F56', '#E025A5', '#530FDD', '#00B1AD', '#47292B', '#B00020'];
                stroke = {
                    curve: 'straight',
                    dashArray: [null, null, null, 10, 10, 10, 5, 5, 5],
                }
                legend = {
                    position: "top",
                    showForSingleSeries: true,
                    markers: {
                        width: [20, 20, 20, 8, 8, 8, 5, 5, 5],
                        height: 4,
                        strokeWidth: 0,
                    },
                    formatter: function (seriesName, opts) {
                        let header = opts.w.config.series[opts.seriesIndex].header;
                        if (convert_peer_to_national_avg_header) {
                            return header.replace("Peer Average", "National Average")
                        }
                        return header
                    }
                }

                if (props.reporttab !== "benchmark" && (useavgdailymilkflag || props.reportResponse?.Use_Avg_Daily_Milk)) {
                    xaxis = {
                        type: "numeric",
                        tickAmount: 37,
                        min: 0,
                        labels: {
                            style: {
                                fontSize: '15px',
                            },
                            formatter: function (value) {
                                return [2, 4, 8, 15, 21, 25, 32, 37].includes(value) ? value : "";
                            }
                        },
                        title: {
                            text: xaxistext,
                            style: {
                                fontSize: '15px',
                                fontWeight: '700',
                            },
                        },
                        "categories": category
                    }
                }
            }

            let data = {
                colors: colors,
                chart: {
                    id: id,
                    name: name,
                    height: 450,
                    width: '100%',
                    type: chartType,
                    toolbar: {
                        show: false,
                    },
                },
                stroke: stroke,
                dataLabels: {
                    enabled: chartType === "line" ? false : true,
                    style: {
                        fontSize: '15px',
                        fontWeight: '700',
                        colors: chartLabelColor,
                    }
                },
                xaxis: xaxis,
                yaxis: yaxis,
                legend: legend,
                tooltip: tooltip,
            };
            return data;
        }

        const getgraphlistarray = (graphResult, curveChart) => {
            const graphListArray = [];
            const graphObject = Object.keys(graphResult).length === 0 && graphResult.constructor === Object;
            const chartType = props.reporttab === "snapsix" ? 'line' : 'bar';
            const chartLabelColor = chartType === 'line' ? undefined : ['#333'];

            let xaxislabel = "Days in Milk";
            if (props.reporttab !== "benchmark" && (useavgdailymilkflag || props.reportResponse?.Use_Avg_Daily_Milk)){
                xaxislabel = 'Weeks in Milk'
            }
            let yaxislabel = "Test Day Milk";
            if (useavgdailymilkflag || (props.reporttab !== "benchmark" && props.reportResponse?.Use_Avg_Daily_Milk)) {
                yaxislabel = "Weekly Milk"
            }

            
            let energyCorrectedMilkSeries = null;
            let energyCorrectedMilkOptions = null;
            if(props.reporttab === "snapsix") {
                energyCorrectedMilkSeries = graphObject ? '' : graphResult.ecm_data[0].ECM_data.filter((d) => { if (d.hasOwnProperty("data")) { return true } return false })
                if (energyCorrectedMilkSeries !== null) {
                    energyCorrectedMilkSeries = energyCorrectedMilkSeries.filter((d) => {
                        if (["Herd_Goal_(60)", "Herd_Goal_(115)"].includes(d.name)) {
                            lactationgraphdontshowgoal = d.name;
                            return false;
                        }
                        return true;
                    })
                    energyCorrectedMilkSeries.forEach((series) => {
                        series.data = series.data.map((data, i) => {
                            if (data === 0) {
                                data = null
                            }
    
                            return data;
                        })
                    })
                }

                energyCorrectedMilkOptions = getoptions('line', chartLabelColor, graphObject ? '' : graphResult.ecm_data[0].categories, "ecmchart", "Energy Corrected Milk Chart", "", "Energy Corrected Milk");
            } else {
                energyCorrectedMilkSeries = graphObject ? '' : graphResult.ecm_data[0].ECM_data;
                energyCorrectedMilkOptions = getoptions(chartType, chartLabelColor, graphObject ? '' : graphResult.ecm_data[0].categories, "ecmchart", "Energy Corrected Milk Chart", "", "Energy Corrected Milk");
            }

            // ***************************************************************************************************
            //             Bar Graphlist
            // ***************************************************************************************************
            // Testday milk chart
            const testDayMilkSeries = graphObject ? '' : graphResult.test_day_milk_data[0].Test_Day_Milk_data;
            const testDayMilkOptions = getoptions(chartType, chartLabelColor, graphObject ? '' : graphResult.test_day_milk_data[0].categories, "testdaymilkchart", "Test Day Milk Chart", '', 'Test Day Milk');

            // Weekly milk chart
            const weeklyMilkSeries = graphObject ? '' : graphResult.weekly_milk_data[0].Latest_Weekly_Milk_data;
            const weeklyMilkOptions = getoptions(chartType, chartLabelColor, graphObject ? '' : graphResult.weekly_milk_data[0].categories, "weeklymilkchart", "Weekly Milk Chart", '', 'Weekly Milk');

            // Percent Pregnant in Herd
            const herdDataSeries = graphObject ? '' : graphResult.percent_pregnant_in_herd_data[0].Reproduction_data;
            const herdDataOptions = getoptions(chartType, chartLabelColor, graphObject ? '' : graphResult.percent_pregnant_in_herd_data[0].categories, "herddatachart", "% Pregnant in Herd", '', 'Percent Pregnant in Herd');

            // 1st Calf Heifer Profiles
            const calfHeiferSeries = graphObject ? '' : graphResult.first_calf_heifer_profiles_data[0].HerdInventory_data;
            const calfHeiferOptions = getoptions(chartType, chartLabelColor, graphObject ? '' : graphResult.first_calf_heifer_profiles_data[0].categories, "calfheiferchart", "1st Calf Heifer Profiles", '', 'Percent Heifers');

            
            // Days Open 
            let daysOpenSeries = graphObject ? '' : graphResult.days_open_data[0].Reproduction_data;
            let daysopencategory = graphResult.days_open_data[0].categories;
            if (daysOpenSeries && props.reporttab !== "snapsix") {
                for (let i = 0; i < daysOpenSeries.length; i++) {
                    daysOpenSeries[i].data = [daysOpenSeries[i].data[0]];
                }

                if (props.reporttab === "periodcomparison" || props.reporttab === "subherdcomparison") {
                    for (let i = 2; i < daysOpenSeries.length; i++) {
                        if (daysOpenSeries[i].data.length > 0) {
                            daysOpenSeries[i].data = [daysOpenSeries[i].data[0]];
                        }
                    }
                }

                daysopencategory = [graphResult.days_open_data[0].categories[0]];
            } else {
                daysOpenSeries = [];
                daysOpenSeries.push(graphResult.days_open_data[0].Reproduction_data[0]);
            }
            
            const daysOpenOptions = getoptions(chartType, chartLabelColor, graphObject ? '' : daysopencategory, "daysopenchart", "Days in Milk", '', 'Days Open');
            
            //Percent Fat
            const percentFatSeries = graphObject ? '' : graphResult.percent_fat_data[0].Percent_Fat_data;
            const percentFatOptions = getoptions(chartType, chartLabelColor, graphObject ? '' : graphResult.percent_fat_data[0].categories, "percentfatchart", "Percent Fat", '', 'Fat(%)');
            
            //Somatic Cell Count
            const somaticCellSeries = graphObject ? '' : graphResult.somatic_cell_count[0].Milk_Quality_data;
            const somaticCellOptions = getoptions(chartType, chartLabelColor, graphObject ? '' : graphResult.somatic_cell_count[0].categories, "somaticcellchart", "Somatic Cell", 'Linear Score', 'Percent');

            //Percent Protein
            const percentProteinSeries = graphObject ? '' : graphResult.percent_protein[0].Percent_Protein_data;
            const percentProteinOptions = getoptions(chartType, chartLabelColor, graphObject ? '' : graphResult.percent_protein[0].categories, "percentproteinchart", "Percent Protein", '', 'Protein(%)');


            //Total lbs fat+protein
            const totalLbsSeries = graphObject ? '' : graphResult.lbs_fat_protein_data[0].ComponentAnalysis_data;
            const totalLbsOptions = getoptions(chartType, chartLabelColor, graphObject ? '' : graphResult.lbs_fat_protein_data[0].categories, "totallbschart", "Total lbs fat+protein", '', 'Fat+Protein, lbs');

            //Average DIM
            const avgDimSeries = graphObject ? '' : graphResult.average_dim[0].Average_DIM_data;
            const avgDimOptions = getoptions(chartType, chartLabelColor, graphObject ? '' : graphResult.average_dim[0].categories, "avgdimchart", "Average DIM", '', 'DIM');

            //Calving Intervals
            const calvingIntervalSeries = graphObject ? '' : graphResult.calving_interval[0].Reproduction_data;
            const calvingIntervalOptions = getoptions(chartType, chartLabelColor, graphObject ? '' : graphResult.calving_interval[0].categories, "calvingintervalchart", "Calving Intervals", '', 'Days');

            //Annual Cull Chart 
            const annualCullSeries = graphObject ? '' : graphResult.annual_cull_and_death_rates[0].Culling_data;
            const annualCullOptions = getoptions(chartType, chartLabelColor, graphObject ? '' : graphResult.annual_cull_and_death_rates[0].categories, "annualcullchart", "Annual Cull", '', 'Percent');


            // ***************************************************************************************************
            //             Line Graphlist
            // ***************************************************************************************************
            // first lactation chart 
            let lactOneSeries = graphObject ? '' : graphResult.lacation_1_data[0].Lactation_1_data.filter((d) => { if (d.hasOwnProperty("data")) { return true } return false })
            if (lactOneSeries !== null) {
                lactOneSeries = lactOneSeries.filter((d) => {
                    if (["Herd_Goal_(60)", "Herd_Goal_(115)"].includes(d.name)) {
                        lactationgraphdontshowgoal = d.name;
                        return false;
                    }
                    return true;
                })
                let test_day_data = [];
                let remove_qtravg1 = false;
                lactOneSeries.forEach((series) => {
                    if (series.name === "test_day_1") {
                        test_day_data = series.data;
                    } 
                    if (series.name === "Quarter_Average") {
                        const areArraysEqual = (arr1, arr2) => {
                            return JSON.stringify(arr1) === JSON.stringify(arr2);
                        };

                        const arraysEqual = areArraysEqual(test_day_data, series.data);

                        if (arraysEqual) {
                            remove_qtravg1 = true;
                        }
                    } else {
                        series.data = series.data.map((data, i) => {
                            
                            if (data === 0) {
                                data = null
                            }

                            return data;
                            // if (props.reporttab !== "benchmark" && (useavgdailymilkflag || props.reportResponse?.Use_Avg_Daily_Milk)) {
                            //     let y = null;
                            //     if (data) {
                            //         y = data.y ? data.y : data
                            //     }
                            //     return {
                            //         x: graphResult.lacation_1_data[0].categories[i],
                            //         y: y
                            //     }
                            // } else {
                            //     return data;
                            // }
                        })
                    }
                })
                if (remove_qtravg1) { // remove quarter average if the data is same as test day
                    lactOneSeries.pop();
                }
            } 
            const lactOneOptions = getoptions('line', chartLabelColor, graphObject ? '' : graphResult.lacation_1_data[0].categories, "lactation1chart", "Lactation 1 Chart", xaxislabel, yaxislabel);

            // second lactation series
            let lactTwoSeries = graphObject ? '' : graphResult.lacation_2_data[0].Lactation_2_data.filter((d) => { if (d.hasOwnProperty("data")) { return true } return false })
            if (lactTwoSeries !== null) {
                lactTwoSeries = lactTwoSeries.filter((d) => {
                    if (["Herd_Goal_(60)", "Herd_Goal_(115)"].includes(d.name)) {
                        lactationgraphdontshowgoal = d.name;
                        return false;
                    }
                    return true;
                })
                let test_day_data = [];
                let remove_qtravg2 = false;
                lactTwoSeries.forEach((series) => {
                    if (series.name === "test_day_1") {
                        test_day_data = series.data;
                    } 
                    if (series.name === "Quarter_Average") {
                        const areArraysEqual = (arr1, arr2) => {
                            return JSON.stringify(arr1) === JSON.stringify(arr2);
                        };

                        const arraysEqual = areArraysEqual(test_day_data, series.data);

                        if (arraysEqual) {
                            remove_qtravg2 = true;
                        }
                    } else {
                        series.data = series.data.map((data, i) => {
                            
                            if (data === 0) {
                                data = null
                            }

                            return data;
                            // if (props.reporttab !== "benchmark" && (useavgdailymilkflag || props.reportResponse?.Use_Avg_Daily_Milk)) {
                            //     let y = null;
                            //     if (data) {
                            //         y = data.y ? data.y : data
                            //     }
                            //     return {
                            //         x: graphResult.lacation_1_data[0].categories[i],
                            //         y: y
                            //     }
                            // } else {
                            //     return data;
                            // }
                        })
                    }
                })
                if (remove_qtravg2) { // remove quarter average if the data is same as test day
                    lactTwoSeries.pop();
                }
            }
            const lactTwoOptions = getoptions('line', chartLabelColor, graphObject ? '' : graphResult.lacation_2_data[0].categories, "lactation2chart", "Lactation 2 Chart", xaxislabel, yaxislabel);

            // third lactation series
            let lactThreeSeries = graphObject ? '' : graphResult.lacation_3_data[0].Lactation_3_data.filter((d) => { if (d.hasOwnProperty("data")) { return true } return false })
            if (lactThreeSeries !== null) {
                lactThreeSeries = lactThreeSeries.filter((d) => {
                    if (["Herd_Goal_(60)", "Herd_Goal_(115)"].includes(d.name)) {
                        lactationgraphdontshowgoal = d.name;
                        return false
                    }
                    return true;
                })
                let test_day_data = [];
                let remove_qtravg3 = false;
                lactThreeSeries.forEach((series) => {
                    if (series.name === "test_day_1") {
                        test_day_data = series.data;
                    } 
                    if (series.name === "Quarter_Average") {
                        const areArraysEqual = (arr1, arr2) => {
                            return JSON.stringify(arr1) === JSON.stringify(arr2);
                        };

                        const arraysEqual = areArraysEqual(test_day_data, series.data);

                        if (arraysEqual) {
                            remove_qtravg3 = true;
                        }
                    } else {
                        series.data = series.data.map((data, i) => {
                            
                            if (data === 0) {
                                data = null
                            }

                            return data;
                            // if (props.reporttab !== "benchmark" && (useavgdailymilkflag || props.reportResponse?.Use_Avg_Daily_Milk)) {
                            //     let y = null;
                            //     if (data) {
                            //         y = data.y ? data.y : data
                            //     }
                            //     return {
                            //         x: graphResult.lacation_1_data[0].categories[i],
                            //         y: y
                            //     }
                            // } else {
                            //     return data;
                            // }
                        })
                    }
                })
                if (remove_qtravg3) { // remove quarter average if the data is same as test day
                    lactThreeSeries.pop();
                }
            }
            const lactThreeOptions = getoptions('line', chartLabelColor, graphObject ? '' : graphResult.lacation_3_data[0].categories, "lactation3chart", "Lactation 3+ Chart", xaxislabel, yaxislabel);

            // Lactation Curve 
            let lactationCurveSeries = curveChart ? curveChart.Goal_data : null;

            if (curveChart && (props.reporttab !== "benchmark" && (useavgdailymilkflag || props.reportResponse?.Use_Avg_Daily_Milk))) {
                curveChart.categories = [2, 4, 8, 15, 21, 25, 32, 37]
            }

            if (lactationCurveSeries) {
                lactationCurveSeries = lactationCurveSeries.map((d) => {
                    d.data = d.data.map((yaxisdata, i) => {

                        return yaxisdata;
                        // if (props.reporttab !== "benchmark" && (useavgdailymilkflag || props.reportResponse?.Use_Avg_Daily_Milk)) {
                        //     let y = null;
                        //     if (yaxisdata) {
                        //         y = yaxisdata.y ? yaxisdata.y : yaxisdata
                        //     }
                        //     return {
                        //         x: curveChart.categories[i],
                        //         y: y
                        //     }
                        // } else {
                        //     return yaxisdata;
                        // }
                    })
                    return d;
                })
            }


            const lactationCurveOptions = getoptions('line', chartLabelColor, curveChart ? curveChart.categories : '', "lactationcurvechart", "Test Day Milk Chart", xaxislabel, yaxislabel);
            graphListArray.push({
                name: 'Energy Corrected Milk',
                id: energyCorrectedMilkOptions.chart.id,
                option: energyCorrectedMilkOptions,
                series: energyCorrectedMilkSeries
            })
            if (props.reporttab !== "cullinghistory") {
                if (!useavgdailymilkflag) {
                    let chartname = "Milk Production";
                    graphListArray.push(
                        {
                            name: chartname,
                            id: testDayMilkOptions.chart.id,
                            series: testDayMilkSeries,
                            option: testDayMilkOptions
                        },
                    )
                } else {
                    let chartname = "Milk Production";
                    graphListArray.push(
                        {
                            name: chartname,
                            id: weeklyMilkOptions.chart.id,
                            series: weeklyMilkSeries,
                            option: weeklyMilkOptions
                        },
                    )
                }
                graphListArray.push(
                    {
                        name: '1st Lactation',
                        id: lactOneOptions.chart.id,
                        series: lactOneSeries,
                        option: lactOneOptions
                    },
                    {
                        name: '2nd Lactation',
                        id: lactTwoOptions.chart.id,
                        series: lactTwoSeries,
                        option: lactTwoOptions
                    },
                    {
                        name: '3rd+ Lactation',
                        id: lactThreeOptions.chart.id,
                        series: lactThreeSeries,
                        option: lactThreeOptions
                    },
                    {
                        name: 'Percent Pregnant in Herd',
                        id: herdDataOptions.chart.id,
                        option: herdDataOptions,
                        series: herdDataSeries
                    },
                    {
                        name: '1st Calf Heifer Profiles',
                        id: calfHeiferOptions.chart.id,
                        option: calfHeiferOptions,
                        series: calfHeiferSeries
                    },
                    {
                        name: 'Days Open',
                        id: daysOpenOptions.chart.id,
                        option: daysOpenOptions,
                        series: daysOpenSeries
                    },
                    {
                        name: 'Percent Fat',
                        id: percentFatOptions.chart.id,
                        option: percentFatOptions,
                        series: percentFatSeries
                    },
                    {
                        name: 'Somatic Cell',
                        id: somaticCellOptions.chart.id,
                        option: somaticCellOptions,
                        series: somaticCellSeries
                    },
                    {
                        name: 'Percent Protein',
                        id: percentProteinOptions.chart.id,
                        option: percentProteinOptions,
                        series: percentProteinSeries
                    },
                    {
                        name: 'Total lbs fat+protein',
                        id: totalLbsOptions.chart.id,
                        option: totalLbsOptions,
                        series: totalLbsSeries
                    },
                    {
                        name: 'Average DIM',
                        id: avgDimOptions.chart.id,
                        option: avgDimOptions,
                        series: avgDimSeries
                    },
                    {
                        name: 'Calving Intervals',
                        id: calvingIntervalOptions.chart.id,
                        option: calvingIntervalOptions,
                        series: calvingIntervalSeries
                    },
                )
            }

            // if (graphResult.test_day_milk_data[0].Test_Day_Milk_data.length === 0 || graphResult.weekly_milk_data[0].Latest_Weekly_Milk_data.length === 0) {
            graphListArray.push({
                name: 'Annual Cull',
                id: annualCullOptions.chart.id,
                option: annualCullOptions,
                series: annualCullSeries
            })
            // }

            if (!["cullinghistory", "snapsix", "periodcomparison", "subherdcomparison"].includes(props.reporttab)) {
                graphListArray.push({
                    name: 'Lactation Curve',
                    id: lactationCurveOptions.chart.id,
                    series: lactationCurveSeries,
                    option: lactationCurveOptions
                })
            }
            
            return graphListArray;
        }

        // change here
        if(!updatedChartWidth) {
            const setgraphdataresponse = (graphResult, curveChart) => {
                const graphListArray = getgraphlistarray(graphResult, curveChart);
                if (props.selectedGraph.length > 0) {
                    if (props.selectedGraph.length > 0) {
                        setGraphlist(graphListArray);
                        if (["cullinghistory", "snapsix", "periodcomparison", "subherdcomparison"].includes(props.reporttab)) {
                            const newChartData = graphTemplate.filter(item => item.key !== "lactationcurvechart");
                            setSelectedGraph(newChartData)
                        } else {
                            setSelectedGraph(props.graphTemplate);
                        }
                    }
                } else {
                    setGraphlist(graphListArray);
                }

                if (props.graphimgurlarray.length > 0) {
                    setgraphbloburl(props.graphimgurlarray)
                }
            }
            setgraphdataresponse(props.graphdataresponse, props.reportResponse.Lacation_graph_values);
        }

        setloaderstatus(false);
        setremovecounter(1);
    }, [props]);

    // selectedGraph
    let apreview = null, bpreview = null, cpreview = null, dpreview = null, epreview = null, fpreview = null, gpreview = null, hpreview = null, ipreview = null, jpreview = null, kpreview = null, lpreview = null, mpreview = null, npreview = null, lactpreview = null, opreview = null

    const capitalizeFirst = (str) => {
        // return str.charAt(0).toUpperCase() + str.slice(1);
        return str.replace(/\b\w/g, (char) => char.toUpperCase());
    };
      
    // function that handles chart pptx download
    const handleDownloadChartPPT = async (currentRef, chartName) => {
        const chartContainer = currentRef.current;
        if (!chartContainer) {
          console.error('Chart container not found.');
          return;
        }
      
        const canvas = await html2canvas(chartContainer);
        if (!canvas) {
          console.error('Canvas not generated.');
          return;
        }
        
        // define the slide dimensions (in inches)
        const slideWidthInches = 7;
        const slideHeightInches = 5.3; 

        const pptx = new PptxGenJS();
        const slide = pptx.addSlide({ masterName: 'Blank', w: slideWidthInches, h: slideHeightInches });
      
        // convert the canvas to a base64 encoded image
        const imgData = canvas.toDataURL('image/png');

        const imgWidthFraction = 0.7; // 70% of slide width
        const imgHeightFraction = 0.6; // 70% of slide height
      
        // calculate the image dimensions based on the slide size
        const imgWidth = slideWidthInches * imgWidthFraction;
        const imgHeight = slideHeightInches * imgHeightFraction;
      
        // calculate the position to center the image
        const imgX = (slideWidthInches - imgWidth) / .9;
        const imgY = (slideHeightInches - imgHeight) / 2;

        slide.addImage({
            data: imgData,
            x: imgX,
            y: imgY,
            w: imgWidth,
            h: imgHeight,
        });

        slide.addImage({
            data: purina_logo,
            x: .1, // Adjust the X position as needed
            y: slideHeightInches - 0.3, // Position it just above the footer
            w: '8%',
            h: 40 / 72,
        });
        
        const todaydate = new Date();
        const today = moment(todaydate).format("l");
        const todaytime = moment(todaydate).format("h:mm a");
        const subherd_selected = props.subherddata.description != undefined ? props.subherddata.description : props.subherddata.subHerdSelected;
        const footerText = "Report generated " + today + " at " + todaytime + " for " + capitalizeFirst(props.dairyname) + " - " + capitalizeFirst(subherd_selected); 
        const footerOptions = {
            x: .9,
            y: slideHeightInches - 0.1, // Adjust the Y position for the footer
            w: '80%',
            h: 0.3, // Height of the footer
            fontSize: 8,
            color: 'gray', // Color of the footer text
            hAlign: 'center', // Center align the text
        };

        slide.addText(footerText, footerOptions);
        
        const pptxBlob = await pptx.write();
        saveAs(pptxBlob, chartName + ' chart.pptx');
    };

    const getchartarray = () => {
        let a = null, b = null, c = null, d = null, e = null, f = null, g = null, h = null, i = null, j = null, k = null, l = null, m = null, n = null, ecm = null, lact = null

        if (props.reporttab !== "cullinghistory") {
            // Energy Corrected Milk
            ecm = graphList.length > 0 ? <Grid item xs={12}>
                <h2>{graphList[0].name}</h2>
                <ReactApexChart
                    id={graphList[0].id}
                    options={graphList[0].option}
                    series={graphList[0].series}
                    type={graphList[0].option.chart.type}
                    height={graphList[0].option.chart.height}
                    width={graphList[0].option.chart.width}
                />
            </Grid> : null;


            let ecmoption = graphList.length === 0 ? null : _.cloneDeep(graphList[0].option)
            let ecm_id = graphList.length === 0 ? "" : graphList[0].id + "preview";  
            if (graphList.length > 0)
                ecmoption.chart.id = ecm_id;
                opreview = graphList.length > 0 ? <Grid item xs={12}>
                    <div ref={ecmChartRef}>
                        <h2>{graphList[0].name}</h2>
                        <ReactApexChart
                            id={ecm_id}
                            options={ecmoption}
                            series={graphList[0].series}
                            type={graphList[0].option.chart.type}
                            height={graphList[0].option.chart.height}
                            width={graphList[0].option.chart.width}
                        />
                    </div>
                    <div className="downloadChart-cont">
                        <Button 
                            variant="contained"
                            className="button"
                            type="button"
                            onClick={() => handleDownloadChartPPT(ecmChartRef, graphList[0].name)}
                        >
                            Download (PPT)
                        </Button>
                    </div>
                </Grid> : null;
        
            // Test Day Milk or Weekly Milk
            a = graphList.length > 0 ? <Grid item xs={12}>
                <div ref={milkprodChartRef}>
                    <h2>Milk Production</h2>
                    <ReactApexChart
                        id={graphList[1].id}
                        options={graphList[1].option}
                        series={graphList[1].series}
                        type={graphList[1].option.chart.type}
                        height={graphList[1].option.chart.height}
                        width={graphList[1].option.chart.width}
                    />
                </div>
            </Grid> : null;

            // Lactation 1
            b = graphList.length > 0 ? <Grid item xs={12}>
                <div ref={lact1ChartRef}>
                    <h2>{graphList[2].name}</h2>
                    <ReactApexChart
                        id={graphList[2].id}
                        options={graphList[2].option}
                        series={graphList[2].series}
                        type={graphList[2].option.chart.type}
                        height={graphList[2].option.chart.height}
                        width={graphList[2].option.chart.width}
                    />
                </div>
            </Grid> : null;

            // Lactation 2
            c = graphList.length > 0 ? <Grid item xs={12}>
                <div ref={lact2ChartRef}>
                    <h2>{graphList[3].name}</h2>
                    <ReactApexChart
                        id={graphList[3].id}
                        options={graphList[3].option}
                        series={graphList[3].series}
                        type={graphList[3].option.chart.type}
                        height={graphList[3].option.chart.height}
                        width={graphList[3].option.chart.width}
                    />
                </div>
            </Grid> : null;

            // Lactation 3
            d = graphList.length > 0 ? <Grid item xs={12}>
                <div ref={lact3ChartRef}>
                    <h2>{graphList[4].name}</h2>
                    <ReactApexChart
                        id={graphList[4].id}
                        options={graphList[4].option}
                        series={graphList[4].series}
                        type={graphList[4].option.chart.type}
                        height={graphList[4].option.chart.height}
                        width={graphList[4].option.chart.width}
                    />
                </div>
            </Grid> : null;
            // }

            // Herd Data
            e = graphList.length > 0 ? <Grid item xs={12}>
                <h2>{graphList[5].name}</h2>
                <ReactApexChart
                    id={graphList[5].id}
                    options={graphList[5].option}
                    series={graphList[5].series}
                    type={graphList[5].option.chart.type}
                    height={graphList[5].option.chart.height}
                    width={graphList[5].option.chart.width}
                />
            </Grid> : null;

            // first Calf Heifer
            f = graphList.length > 0 ? <Grid item xs={12}>
                <h2>{graphList[6].name}</h2>
                <ReactApexChart
                    id={graphList[6].id}
                    options={graphList[6].option}
                    series={graphList[6].series}
                    type={graphList[6].option.chart.type}
                    height={graphList[6].option.chart.height}
                    width={graphList[6].option.chart.width}
                />
            </Grid> : null;

            // Days Open
            g = graphList.length > 0 ? <Grid item xs={12}>
                <h2>{graphList[7].name}</h2>
                <ReactApexChart
                    id={graphList[7].id}
                    options={graphList[7].option}
                    series={graphList[7].series}
                    type={graphList[7].option.chart.type}
                    height={graphList[7].option.chart.height}
                    width={graphList[7].option.chart.width}
                />
            </Grid> : null;

            // Percent Fat
            h = graphList.length > 0 ? <Grid item xs={12}>
                <h2>{graphList[8].name}</h2>
                <ReactApexChart
                    id={graphList[8].id}
                    options={graphList[8].option}
                    series={graphList[8].series}
                    type={graphList[8].option.chart.type}
                    height={graphList[8].option.chart.height}
                    width={graphList[8].option.chart.width}
                />
            </Grid> : null;

            // Somatic Cell
            i = graphList.length > 0 ? <Grid item xs={12}>
                <h2>{graphList[9].name}</h2>
                <ReactApexChart
                    id={graphList[9].id}
                    options={graphList[9].option}
                    series={graphList[9].series}
                    type={graphList[9].option.chart.type}
                    height={graphList[9].option.chart.height}
                    width={graphList[9].option.chart.width}
                />
            </Grid> : null;

            // Percent Protein
            j = graphList.length > 0 ? <Grid item xs={12}>
                <h2>{graphList[10].name}</h2>
                <ReactApexChart
                    id={graphList[10].id}
                    options={graphList[10].option}
                    series={graphList[10].series}
                    type={graphList[10].option.chart.type}
                    height={graphList[10].option.chart.height}
                    width={graphList[10].option.chart.width}
                />
            </Grid> : null;

            // Total lbs fat+protein
            k = graphList.length > 0 ? <Grid item xs={12}>
                <h2>{graphList[11].name}</h2>
                <ReactApexChart
                    id={graphList[11].id}
                    options={graphList[11].option}
                    series={graphList[11].series}
                    type={graphList[11].option.chart.type}
                    height={graphList[11].option.chart.height}
                    width={graphList[11].option.chart.width}
                />
            </Grid> : null;

            // Average DIM
            l = graphList.length > 0 ? <Grid item xs={12}>
                <h2>{graphList[12].name}</h2>
                <ReactApexChart
                    id={graphList[12].id}
                    options={graphList[12].option}
                    series={graphList[12].series}
                    type={graphList[12].option.chart.type}
                    height={graphList[12].option.chart.height}
                    width={graphList[12].option.chart.width}
                />
            </Grid> : null;

            // Calving Intervals
            m = graphList.length > 0 ? <Grid item xs={12}>
                <h2>{graphList[13].name}</h2>
                <ReactApexChart
                    id={graphList[13].id}
                    options={graphList[13].option}
                    series={graphList[13].series}
                    type={graphList[13].option.chart.type}
                    height={graphList[13].option.chart.height}
                    width={graphList[13].option.chart.width}
                />
            </Grid> : null;

            // ##########################################################################################
            // Use for Preview Only
            // ##########################################################################################

            // Test Day Milk or Weekly Milk
            let aoption = graphList.length === 0 ? null : _.cloneDeep(graphList[1].option)
            let aid = graphList.length === 0 ? "" : graphList[1].id + "preview";
            if (graphList.length > 0)
                aoption.chart.id = aid;
            apreview = graphList.length > 0 ? <Grid item xs={12}>
                <div ref={milkprodChartRef}>
                    <h2>{graphList[1].name}</h2>
                    <ReactApexChart
                        id={aid}
                        options={aoption}
                        series={graphList[1].series}
                        type={graphList[1].option.chart.type}
                        height={graphList[1].option.chart.height}
                        width={graphList[1].option.chart.width}
                    />
                </div>
                <div className="downloadChart-cont">
                    <Button 
                        variant="contained"
                        className="button"
                        type="button"
                        onClick={() => handleDownloadChartPPT(milkprodChartRef, graphList[1].name)}
                    >
                        Download (PPT)
                    </Button>
                </div>
            </Grid> : null;

            // Lactation 1
            let boption = graphList.length === 0 ? null : _.cloneDeep(graphList[2].option)
            let bid = graphList.length === 0 ? "" : graphList[2].id + "preview";
            if (graphList.length > 0)
                boption.chart.id = bid;
            bpreview = graphList.length > 0 ? <Grid item xs={12}>
                <div ref={lact1ChartRef}>
                    <h2>{graphList[2].name}</h2>
                    <ReactApexChart
                        id={bid}
                        options={boption}
                        series={graphList[2].series}
                        type={graphList[2].option.chart.type}
                        height={graphList[2].option.chart.height}
                        width={graphList[2].option.chart.width}
                    />
                </div>
                <div className="downloadChart-cont">
                    <Button 
                        variant="contained"
                        className="button"
                        type="button"
                        onClick={() => handleDownloadChartPPT(lact1ChartRef, graphList[2].name)}
                    >
                        Download (PPT)
                    </Button>
                </div>
            </Grid> : null;

            // Lactation 2
            let coption = graphList.length === 0 ? null : _.cloneDeep(graphList[3].option)
            let cid = graphList.length === 0 ? "" : graphList[3].id + "preview";
            if (graphList.length > 0)
                coption.chart.id = cid;
            cpreview = graphList.length > 0 ? <Grid item xs={12}>
                <div ref={lact2ChartRef}>
                    <h2>{graphList[3].name}</h2>
                    <ReactApexChart
                        id={cid}
                        options={coption}
                        series={graphList[3].series}
                        type={graphList[3].option.chart.type}
                        height={graphList[3].option.chart.height}
                        width={graphList[3].option.chart.width}
                    />
                </div>
                <div className="downloadChart-cont">
                    <Button 
                        variant="contained"
                        className="button"
                        type="button"
                        onClick={() => handleDownloadChartPPT(lact2ChartRef, graphList[3].name)}
                    >
                        Download (PPT)
                    </Button>
                </div>
            </Grid> : null;

            // Lactation 3
            let doption = graphList.length === 0 ? null : _.cloneDeep(graphList[4].option)
            let did = graphList.length === 0 ? "" : graphList[4].id + "preview";
            if (graphList.length > 0)
                doption.chart.id = did;
            dpreview = graphList.length > 0 ? <Grid item xs={12}>
                <div ref={lact3ChartRef}>
                    <h2>{graphList[4].name}</h2>
                    <ReactApexChart
                        id={did}
                        options={doption}
                        series={graphList[4].series}
                        type={graphList[4].option.chart.type}
                        height={graphList[4].option.chart.height}
                        width={graphList[4].option.chart.width}
                    />
                </div>
                <div className="downloadChart-cont">
                    <Button 
                        variant="contained"
                        className="button"
                        type="button"
                        onClick={() => handleDownloadChartPPT(lact3ChartRef, graphList[4].name)}
                    >
                        Download (PPT)
                    </Button>
                </div>
            </Grid> : null;
            // }

            // Herd Data
            let eoption = graphList.length === 0 ? null : _.cloneDeep(graphList[5].option)
            let eid = graphList.length === 0 ? "" : graphList[5].id + "preview";
            if (graphList.length > 0)
                eoption.chart.id = eid;
            epreview = graphList.length > 0 ? <Grid item xs={12}>
                <div ref={herddataChartRef}>
                    <h2>{graphList[5].name}</h2>
                    <ReactApexChart
                        id={eid}
                        options={eoption}
                        series={graphList[5].series}
                        type={graphList[5].option.chart.type}
                        height={graphList[5].option.chart.height}
                        width={graphList[5].option.chart.width}
                    />
                </div>
                <div className="downloadChart-cont">
                    <Button 
                        variant="contained"
                        className="button"
                        type="button"
                        onClick={() => handleDownloadChartPPT(herddataChartRef, graphList[5].name)}
                    >
                        Download (PPT)
                    </Button>
                </div>
            </Grid> : null;

            // first Calf Heifer
            let foption = graphList.length === 0 ? null : _.cloneDeep(graphList[6].option)
            let fid = graphList.length === 0 ? "" : graphList[6].id + "preview";
            if (graphList.length > 0)
                foption.chart.id = fid;
            fpreview = graphList.length > 0 ? <Grid item xs={12}>
                <div ref={firstcalfheiferChartRef}>
                    <h2>{graphList[6].name}</h2>
                    <ReactApexChart
                        id={fid}
                        options={foption}
                        series={graphList[6].series}
                        type={graphList[6].option.chart.type}
                        height={graphList[6].option.chart.height}
                        width={graphList[6].option.chart.width}
                    />
                </div>
                <div className="downloadChart-cont">
                    <Button 
                        variant="contained"
                        className="button"
                        type="button"
                        onClick={() => handleDownloadChartPPT(firstcalfheiferChartRef, graphList[6].name)}
                    >
                        Download (PPT)
                    </Button>
                </div>
            </Grid> : null;

            // Days Open
            let goption = graphList.length === 0 ? null : _.cloneDeep(graphList[7].option)
            let gid = graphList.length === 0 ? "" : graphList[7].id + "preview";
            if (graphList.length > 0)
                goption.chart.id = gid;
            gpreview = graphList.length > 0 ? <Grid item xs={12}>
                <div ref={daysOpenChartRef}>
                    <h2>{graphList[7].name}</h2>
                    <ReactApexChart
                        id={gid}
                        options={goption}
                        series={graphList[7].series}
                        type={graphList[7].option.chart.type}
                        height={graphList[7].option.chart.height}
                        width={graphList[7].option.chart.width}
                    />
                </div>
                <div className="downloadChart-cont">
                    <Button 
                        variant="contained"
                        className="button"
                        type="button"
                        onClick={() => handleDownloadChartPPT(daysOpenChartRef, graphList[7].name)}
                    >
                        Download (PPT)
                    </Button>
                </div>
            </Grid> : null;

            // Percent Fat
            let hoption = graphList.length === 0 ? null : _.cloneDeep(graphList[8].option)
            let hid = graphList.length === 0 ? "" : graphList[8].id + "preview";
            if (graphList.length > 0)
                hoption.chart.id = hid;
            hpreview = graphList.length > 0 ? <Grid item xs={12}>
                <div ref={percentFatChartRef}>
                    <h2>{graphList[8].name}</h2>
                    <ReactApexChart
                        id={hid}
                        options={hoption}
                        series={graphList[8].series}
                        type={graphList[8].option.chart.type}
                        height={graphList[8].option.chart.height}
                        width={graphList[8].option.chart.width}
                    />
                </div>
                <div className="downloadChart-cont">
                    <Button 
                        variant="contained"
                        className="button"
                        type="button"
                        onClick={() => handleDownloadChartPPT(percentFatChartRef, graphList[8].name)}
                    >
                        Download (PPT)
                    </Button>
                </div>
            </Grid> : null;

            // Somatic Cell
            let ioption = graphList.length === 0 ? null : _.cloneDeep(graphList[9].option)
            let iid = graphList.length === 0 ? "" : graphList[9].id + "preview";
            if (graphList.length > 0)
                ioption.chart.id = iid;
            ipreview = graphList.length > 0 ? <Grid item xs={12}>
                <div ref={somaticCellChartRef}>
                    <h2>{graphList[9].name}</h2>
                    <ReactApexChart
                        id={iid}
                        options={ioption}
                        series={graphList[9].series}
                        type={graphList[9].option.chart.type}
                        height={graphList[9].option.chart.height}
                        width={graphList[9].option.chart.width}
                    />
                </div>
                <div className="downloadChart-cont">
                    <Button 
                        variant="contained"
                        className="button"
                        type="button"
                        onClick={() => handleDownloadChartPPT(somaticCellChartRef, graphList[9].name)}
                    >
                        Download (PPT)
                    </Button>
                </div>
            </Grid> : null;

            // Percent Protein
            let joption = graphList.length === 0 ? null : _.cloneDeep(graphList[10].option)
            let jid = graphList.length === 0 ? "" : graphList[10].id + "preview";
            if (graphList.length > 0)
                joption.chart.id = jid;
            jpreview = graphList.length > 0 ? <Grid item xs={12}>
                <div ref={percentProteinChartRef}>
                    <h2>{graphList[10].name}</h2>
                    <ReactApexChart
                        id={jid}
                        options={joption}
                        series={graphList[10].series}
                        type={graphList[10].option.chart.type}
                        height={graphList[10].option.chart.height}
                        width={graphList[10].option.chart.width}
                    />
                </div>
                <div className="downloadChart-cont">
                    <Button 
                        variant="contained"
                        className="button"
                        type="button"
                        onClick={() => handleDownloadChartPPT(percentProteinChartRef, graphList[10].name)}
                    >
                        Download (PPT)
                    </Button>
                </div>
            </Grid> : null;

            // Total lbs fat+protein
            let koption = graphList.length === 0 ? null : _.cloneDeep(graphList[11].option)
            let kid = graphList.length === 0 ? "" : graphList[11].id + "preview";
            if (graphList.length > 0)
                koption.chart.id = kid;
            kpreview = graphList.length > 0 ? <Grid item xs={12}>
                <div ref={totalLbsFatproteinChartRef}>
                    <h2>{graphList[11].name}</h2>
                    <ReactApexChart
                        id={kid}
                        options={koption}
                        series={graphList[11].series}
                        type={graphList[11].option.chart.type}
                        height={graphList[11].option.chart.height}
                        width={graphList[11].option.chart.width}
                    />
                </div>
                <div className="downloadChart-cont">
                    <Button 
                        variant="contained"
                        className="button"
                        type="button"
                        onClick={() => handleDownloadChartPPT(totalLbsFatproteinChartRef, graphList[11].name)}
                    >
                        Download (PPT)
                    </Button>
                </div>
            </Grid> : null;

            // Average DIM
            let loption = graphList.length === 0 ? null : _.cloneDeep(graphList[12].option)
            let lid = graphList.length === 0 ? "" : graphList[12].id + "preview";
            if (graphList.length > 0)
                loption.chart.id = lid;
            lpreview = graphList.length > 0 ? <Grid item xs={12}>
                <div ref={averageDimChartRef}>
                    <h2>{graphList[12].name}</h2>
                    <ReactApexChart
                        id={lid}
                        options={loption}
                        series={graphList[12].series}
                        type={graphList[12].option.chart.type}
                        height={graphList[12].option.chart.height}
                        width={graphList[12].option.chart.width}
                    />
                </div>
                <div className="downloadChart-cont">
                    <Button 
                        variant="contained"
                        className="button"
                        type="button"
                        onClick={() => handleDownloadChartPPT(averageDimChartRef, graphList[12].name)}
                    >
                        Download (PPT)
                    </Button>
                </div>
            </Grid> : null;

            // Calving Intervals
            let moption = graphList.length === 0 ? null : _.cloneDeep(graphList[13].option)
            let mid = graphList.length === 0 ? "" : graphList[13].id + "preview";
            if (graphList.length > 0)
                moption.chart.id = mid;
            mpreview = graphList.length > 0 ? <Grid item xs={13}>
                <div ref={calvingIntervalsChartRef}>
                    <h2>{graphList[13].name}</h2>
                    <ReactApexChart
                        id={mid}
                        options={moption}
                        series={graphList[13].series}
                        type={graphList[13].option.chart.type}
                        height={graphList[13].option.chart.height}
                        width={graphList[13].option.chart.width}
                    />
                </div>
                <div className="downloadChart-cont">
                    <Button 
                        variant="contained"
                        className="button"
                        type="button"
                        onClick={() => handleDownloadChartPPT(calvingIntervalsChartRef, graphList[13].name)}
                    >
                        Download (PPT)
                    </Button>
                </div>
            </Grid> : null;
        }


        // Annual Cull
        n = graphList.length > 0 ? <Grid item xs={12}>
            <h2>{graphList[14].name}</h2>
            <ReactApexChart
                id={graphList[14].id}
                options={graphList[14].option}
                series={graphList[14].series}
                type={graphList[14].option.chart.type}
                height={graphList[14].option.chart.height}
                width={graphList[14].option.chart.width}
            />
        </Grid> : null;
        //Lactation Curve
        if (!["cullinghistory", "snapsix", "periodcomparison", "subherdcomparison"].includes(props.reporttab)) {
            lact = graphList.length > 0 ? <Grid item xs={12}>
                <h2>{graphList[15].name}</h2>
                <ReactApexChart
                    id={graphList[15].id}
                    options={graphList[15].option}
                    series={graphList[15].series}
                    type={graphList[15].option.chart.type}
                    height={graphList[15].option.chart.height}
                    width={graphList[15].option.chart.width}
                />
            </Grid> : null;
        } else {
            lact = null
        }


        // Annual Cull
        let noption = graphList.length === 0 ? null : _.cloneDeep(graphList[14].option)
        let nid = graphList.length === 0 ? "" : graphList[14].id + "preview";
        if (graphList.length > 0)
            noption.chart.id = nid;
        npreview = graphList.length > 0 ? <Grid item xs={12}>
            <div ref={annualCullChartRef}>
                <h2>{graphList[14].name}</h2>
                <ReactApexChart
                    id={nid}
                    options={noption}
                    series={graphList[14].series}
                    type={graphList[14].option.chart.type}
                    height={graphList[14].option.chart.height}
                    width={graphList[14].option.chart.width}
                />
            </div>
            <div className="downloadChart-cont">
                <Button 
                    variant="contained"
                    className="button"
                    type="button"
                    onClick={() => handleDownloadChartPPT(annualCullChartRef, graphList[14].name)}
                >
                    Download (PPT)
                </Button>
            </div>
        </Grid> : null;
        //Lactation Curve
        if (!["cullinghistory", "snapsix", "periodcomparison", "subherdcomparison"].includes(props.reporttab)) {
            let lactoption = graphList.length === 0 ? null : _.cloneDeep(graphList[15].option)
            let lactid = graphList.length === 0 ? "" : graphList[15].id + "preview";
            if (graphList.length > 0)
                lactoption.chart.id = lactid;
                lactpreview = graphList.length > 0 ? <Grid item xs={12}>
                <div ref={lactCurveChartRef}>
                    <h2>{graphList[15].name}</h2>
                    <ReactApexChart
                        id={lactid}
                        options={lactoption}
                        series={graphList[15].series}
                        type={graphList[15].option.chart.type}
                        height={graphList[15].option.chart.height}
                        width={graphList[15].option.chart.width}
                    />
                </div>
                <div className="downloadChart-cont">
                    <Button 
                        variant="contained"
                        className="button"
                        type="button"
                        onClick={() => handleDownloadChartPPT(lactCurveChartRef, graphList[15].name)}
                    >
                        Download (PPT)
                    </Button>
                </div>
            </Grid> : null;
        } else {
            lactpreview = null
        }

        return [ecm, a, b, c, d, e, f, g, h, i, j, k, l, m, n, lact]
    }

    const handleEnableChart = (chart_id, value) => {
        props.setGraphTemplate((prevGraphTemplate) => 
            prevGraphTemplate.map((item) =>
                item.id === chart_id ? { ...item, value } : item
            )
        );
    }

    const handleEnableChartModal = (chart_id, value) => {
        setGraphTemplate((prevGraphTemplate) => 
            prevGraphTemplate.map((item) =>
                item.id === chart_id ? { ...item, value } : item
            )
        );
    }

    const rendergraphhtml = (graphselected, index) => {
        let widthselection = null, viewexample = null, canceladd = null, enablechart = null;
        canceladd = <>
            <span style={{ cursor: "pointer", display: "inline-block", margin: "8px 0" }} onClick={() => {
                let temp = [...selectedGraph];
                temp = temp.filter((d) => d !== "new");
                setSelectedGraph(temp);
                setaddchartflag(false);
            }}>Cancel</span>
        </>

        if (index >= 0) {
            if (graphselected.name !== "new") {
                enablechart = <React.Fragment>
                    <FormControlLabel
                        style={{ marginTop: "15px" ,marginLeft: "1px" }}
                        control={
                            <Switch
                                id={graphselected.id}
                                size="small"
                                value={props.graphTemplate[index].value}
                                checked={props.graphTemplate[index].value}
                                onChange={(event) => handleEnableChart(graphselected.id, event.target.checked, index)}
                            />
                        }
                        label="Enable chart"
                    />
                </React.Fragment>

                viewexample = <span onClick={async () => {
                    setpreviewchartid(graphselected.id);
                    setshowpreview(true);
                }}
                    className="link-preview-graph"
                >
                    Preview
                <i className="ag-icon ag-icon-insert-chart"></i>
                </span>

                widthselection = <React.Fragment>
                    <Dropdown
                        handler={(event) => {
                            setGraphwidth(event, index);
                        }}
                        value={graphTemplate[index].width}
                        data={{
                            className: "drop-down-root required-style",
                            label: "Select a Width",
                            name: "graphwidth",
                            id: "graphwidth",
                            options: [{
                                name: "Full Page",
                                value: "full_page"
                            }, {
                                name: "Half Page",
                                value: "half_page"
                            }, {
                                name: "One Third of a Page",
                                value: "one_third"
                            }]
                        }} />
                </React.Fragment>
            }
        }

        let chartCounter = index + 1;
        if (index < 0) {
            chartCounter = 1;
        }

        return <React.Fragment>
            <Grid container spacing={2} className="reporting-chart-controls top-border">
                <Grid item xs={8}>
                    <label className="form-label">Chart {chartCounter}</label>
                </Grid>
                <Grid item xs={4} style={{ textAlign: "right" }}>
                    {selectedGraph.length - 1 === index && addchartflag ? null : viewexample}
                </Grid>
                <Grid item xs={6}>
                    <TextField 
                        variant="outlined"
                        className="text-field-root"
                        type="text"
                        value={graphselected.name === "Test Day Milk" ? "Milk Production" : graphselected.name}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <FormControl variant="outlined" className="drop-down-root required-style hidden">
                        <InputLabel htmlFor="outlined-age-native-simple">Select a Chart/Graph</InputLabel>
                        <Select
                            native
                            value={graphselected.id === "" ? "" : graphselected.id}
                            onChange={async (event) => {
                                let selectedGraphid = selectedGraph.map((d) => {
                                    return d.id;
                                })

                                if (selectedGraphid.includes(event.target.value)) {
                                    alert("Graph already Selected");
                                    event.target.value = "";
                                } else {
                                    let temp = [];
                                    let graphimgurlarray = [...graphbloburl];
                                    if (index === -1) {
                                        temp.push({
                                            id: event.target.value,
                                            name: graphidnamemap[event.target.value],
                                            width: DEFAULT_CHART_WIDTH
                                        });
                                    } else {
                                        let graphindex = index;
                                        if (index === -2) {
                                            graphindex = selectedGraph.length;
                                        }

                                        temp = [...selectedGraph];
                                        temp[graphindex] = {
                                            id: event.target.value,
                                            name: graphidnamemap[event.target.value],
                                            width: DEFAULT_CHART_WIDTH
                                        }

                                        if (index >= 0) {
                                            graphimgurlarray = graphimgurlarray.filter((d) => {
                                                if (d.id === selectedGraph[graphindex].id) {
                                                    return false;
                                                }

                                                return true;
                                            })
                                        }
                                    }

                                    setgraphbloburl(graphimgurlarray);
                                    setSelectedGraph(temp);
                                    props.setgraphdata(temp);
                                    props.setchartgraph(temp);
                                    setaddchartflag(false);
                                }
                            }}
                            label="Select a Chart/Graph"
                        >
                            <option value=""></option>
                            {graphList.map((item, i) => {
                                return <option key={i} value={item.id}>{item.name} </option>
                            })}
                        </Select>
                    </FormControl>
                </Grid>

                {/* Select a width */}
                <Grid item xs={4}>
                    {addchartflag && selectedGraph.length - 1 === index ? canceladd : null}
                    {selectedGraph.length - 1 === index && addchartflag ? null : widthselection}
                </Grid>
                {/* Enable/Disable Row */}
                <Grid item xs={2} className="chart-actions">
                    {selectedGraph.length - 1 === index && addchartflag ? null : <>{enablechart}</>}
                </Grid>

            </Grid>
        </React.Fragment>
    }
    
    const setGraphwidth = (event, index) => {
        if(props.customLayout) {
            props.setCustomLayoutWithChanges(true)
        }
        let temp = [...graphTemplate];
        const updatedData = graphTemplate.map(item => {
            if (Number(item.key) === Number(index + 1)) {
                return { ...item, width: event.target.value };
            }
            return item;
        });
        setGraphTemplate(updatedData);
        setUpdatedCartWidth(true)

        temp[index] = {
            key: selectedGraph[index].key,
            id: selectedGraph[index].id,
            name: selectedGraph[index].name,
            value: selectedGraph[index].value,
            width: event.target.value
        }
        
        setSelectedGraph(temp);
        props.setchartgraph(temp);
        props.setgraphdata(temp);
        props.setGraphTemplate(temp);

        if (["cullinghistory", "snapsix", "periodcomparison", "subherdcomparison"].includes(props.reporttab)) {
            const newChartData = temp.filter(item => item.key !== "lactationcurvechart");
            setSelectedGraph(newChartData)
        } else {
            setSelectedGraph(temp);
        }
    }

    const toogleModal = () => {
        setOpenModal(!openModal);
    }

    const handleToogleModal = () => { 
        // setGraphTemplate(initialGraphTemplate);
        setOpenModal(!openModal);
    }

    // Code for dragging row
    // Helper function to reorder the items array
    const reorderItems = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
          return; // The item was not dropped in a valid droppable area
        }
    
        // Reorder the items array and update state
        const reorderedItems = reorderItems(
          graphTemplate,
          result.source.index,
          result.destination.index
        );
        setGraphTemplate(reorderedItems);
        // setSelectedGraph(reorderedItems);
    };
    
    const updateChartOrder = () => {
        props.setGraphTemplate(graphTemplate)
        setOpenModal(false)
    }

    return (
        <div className="graphWrapper">
            <Grid item xs={12} style={{ textAlign: "end" }}>
                 <Button
                    variant="contained"
                    className="button grey size-mini"
                    type="button"
                    size="small"
                    onClick={handleToogleModal}
                >
                    Drag and Drop Order
                </Button>
            </Grid>
            <div className="hidechartfromview">
                {
                    getchartarray().map((d, i) => {
                        return <React.Fragment key={i}>
                            <div style={{ width: "700px" }}>{d}</div>
                        </React.Fragment>
                    })
                }
            </div>
            {
                loaderstatus ? <Loader radius={50} type="large" /> :
                    <>
                        {
                            selectedGraph.length === 0 ? rendergraphhtml({
                                name: "new",
                                id: ""
                            }, -1) :
                            <React.Fragment>
                                {(() => {
                                    let graphsToRender;
                                    if (["cullinghistory", "snapsix", "periodcomparison", "subherdcomparison"].includes(props.reporttab)) {
                                        const newChartData = graphTemplate.filter(item => item.id !== "lactationcurvechart");
                                        graphsToRender = newChartData;
                                    } else {
                                        graphsToRender = props.graphTemplate;
                                    }
                                    return graphsToRender.map((graph, i) => (
                                        <React.Fragment key={i}>
                                            {rendergraphhtml(graph, i)}
                                        </React.Fragment>
                                    ));
                                })()}
                            </React.Fragment>
                        }
                    </>
            }
            <ModalFlyout state={showpreview} setstate={setshowpreview}
                width="xl"
                id={props.id}
            >
                <div>
                    {/* <h2>{graphList.find((d) => d.id === previewchartid)?.name}</h2>
                    <img src={previewimgurl} alt="previewgraph" /> */}
                    {previewchartid === "ecmchart" ? opreview : null}
                    {previewchartid === "testdaymilkchart" || previewchartid === "weeklymilkchart" ? apreview : null}
                    {previewchartid === "lactation1chart" ? bpreview : null}
                    {previewchartid === "lactation2chart" ? cpreview : null}
                    {previewchartid === "lactation3chart" ? dpreview : null}
                    {previewchartid === "herddatachart" ? epreview : null}
                    {previewchartid === "calfheiferchart" ? fpreview : null}
                    {previewchartid === "daysopenchart" ? gpreview : null}
                    {previewchartid === "percentfatchart" ? hpreview : null}
                    {previewchartid === "somaticcellchart" ? ipreview : null}
                    {previewchartid === "percentproteinchart" ? jpreview : null}
                    {previewchartid === "totallbschart" ? kpreview : null}
                    {previewchartid === "avgdimchart" ? lpreview : null}
                    {previewchartid === "calvingintervalchart" ? mpreview : null}
                    {previewchartid === "annualcullchart" ? npreview : null}
                    {previewchartid === "lactationcurvechart" ? lactpreview : null}
                </div>
            </ModalFlyout>
            <Modal
                open={openModal}
                onClose={toogleModal}
                aria-describedby="transition-modal-description"
                className={classes.modal}
                closeAfterTransition
                BackdropComponent={Backdrop}
            >
                 <Fade in={openModal}>
                    <Grid container className={classes.paper}>
                        <Grid item xs={12} style={{ textAlign: "left" }}>
                            <Typography id="keep-mounted-modal-title" className="chartmodal_title" variant="h6" component="h2">
                                Drag and Drop Chart Order
                                <p>Drag and drop the row to change the order of the chart</p>
                            </Typography>
                            
                                <div>
                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {(provided) => (
                                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                                    {graphTemplate.map((item, index) => {
                                                        const isSpecialTab = ["cullinghistory", "snapsix", "periodcomparison", "subherdcomparison"].includes(props.reporttab);
                                                        const isLactationCurveChart = item.id === "lactationcurvechart";
                                                        if (isSpecialTab && isLactationCurveChart) {
                                                            return null; // Skip rendering the "lactationcurvechart" for special tabs
                                                        }
                                                        return (
                                                            <Draggable key={item.key} draggableId={String(item.key)} index={index}>
                                                                {(provided) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={{
                                                                            padding: '3px 10px',
                                                                            marginTop: '5px',
                                                                            borderBottom: '1px solid rgba(128, 128, 120, 0.2)',
                                                                            ...provided.draggableProps.style, // This is required for the drag-and-drop to work correctly
                                                                        }}
                                                                    >
                                                                        {item.name === "Test Day Milk" ? "Milk Production" : item.name}
                                                                        <span className='icon-right'>
                                                                            <Switch
                                                                                id={item.id}
                                                                                size="small"
                                                                                value={graphTemplate[index].value}
                                                                                checked={graphTemplate[index].value}
                                                                                className="modal-switch"
                                                                                onChange={(event) => handleEnableChartModal(item.id, event.target.checked)}
                                                                            />
                                                                            <DragHandleIcon />
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    })}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                        <div className="chart_modal_action">
                                            <Button
                                                variant="contained"
                                                className="button"
                                                type="button"
                                                size="small"
                                                onClick={() => {
                                                    updateChartOrder();
                                                }}
                                            >
                                                Update Chart Order
                                            </Button>
                                        </div>
                                    </DragDropContext>
                                </div>
                        </Grid>
                    </Grid>
                </Fade>
            </Modal>
        </div>
    );
}

export default ChartAndGraphs;